import React, { useState, useEffect, useRef } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import axios from 'axios';
import { Container, Typography, Button, Box } from '@mui/material';

const QrScannerComponent = () => {
  const [data, setData] = useState('No result');
  const [isValid, setIsValid] = useState(null);
  const [startScan, setStartScan] = useState(false);
  const videoRef = useRef(null);
  const codeReader = new BrowserMultiFormatReader();

  useEffect(() => {
    if (startScan && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          codeReader.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
            if (result) {
              setData(result.getText());
              setStartScan(false);
              codeReader.reset();
              validateQrCode(result.getText());
            }
            if (err && !(err instanceof NotFoundException)) {
              console.error(err);
            }
          });
        })
        .catch((err) => {
          console.error('Erro ao acessar a câmera:', err);
        });
    }

    return () => {
      codeReader.reset();
    };
  }, [startScan]);

  const validateQrCode = async (code) => {
    console.log('tentando validar...');
    try {
      const response = await axios.post('https://api.boifestmarilia.com.br/valida.php', { code });
      console.log(response);
      setIsValid(response.data.isValid);
    } catch (err) {
      console.error('Erro ao validar o QR Code:', err);
      setIsValid(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h5" gutterBottom>
        Leitura do QR Code 
      </Typography>
      <Box mb={2}>
        <video ref={videoRef} style={{ width: '100%' }} />
      </Box>
      {!startScan && (
        <Button variant="contained" color="primary" onClick={() => setStartScan(true)}>
          Iniciar Scan
        </Button>
      )}
      <Typography variant="body1">Código de leitura: {data}</Typography>
      {isValid !== null && (
        <Typography variant="h4" color={isValid ? 'green' : 'red'}>
          {isValid ? 'Código Validado' : 'Código Inválido'}
        </Typography>
      )}
    </Container>
  );
};

export default QrScannerComponent;
