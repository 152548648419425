import React from 'react';
import QrScannerComponent from './components/QrScanner';
import { Container, Typography } from '@mui/material';

function App() {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Boi Fest - Validação
      </Typography>
      <QrScannerComponent />
    </Container>
  );
}

export default App;
